import React from 'react';

import LayoutEs from '../components/LayoutEs';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Contact from '../components/Contact';
import SEO from '../components/Seo';
import Intro from '../components/Marketplace/Intro';
import Projects from '../components/Proyectos';
import Calendly from '../components/Calendly';
import ScrollToTop from '../components/ScrollToTop';
import { navigate } from 'gatsby';

class MarketplaceEcommerce extends React.Component {
  render() {
    return (
      <LayoutEs>
        <Header />
        <SEO
          title="Soluciones Marketplace para empresas que buscan ir más allá en su estrategia de comercio electrónico."
          description="Implementa tu marketplace, ofrece una gama de productos más amplia y da un paso adelante en tu estrategia ecommerce."
          canonicalUrl="https://www.bekome.digital/marketplace-ecommerce/"
        />

        <header className="masthead secondary ecommerce-b2b d-flex">
          <div className="container text-center my-auto">
            <h1 className="mb-1 text-primary">Marketplace</h1>
            <h2 className="mb-4">
                soluciones multivendor para un crecimiento sin límites
            </h2>
            <p className='h2 mb-5'>
                ¿Te gustaría aumentar los <strong class="text-primary">beneficios</strong> de tu eCommerce?<br />
                Ampliar tu catálogo de productos a través de un marketplace te ayudará a llegar a más personas y dotará a tu negocio de <strong class="text-primary">mayor estabilidad</strong>.<br />
                ¿Preparado para diversificar tus ingresos?
            </p>
            <button className='btn btn-primary-green btn-lg' onClick={() => {navigate("/contacto/")}}>Quiero lanzar un marketplace</button>
          </div>
          <div className="overlay"></div>
        </header>

        <Intro />
        
        <div className='container-fluid bg-grey'>
          <h3 className="font-size__l text-secondary text-center pt-5">
            ¿Te gustaría disfrutar de estas ventajas con tu Marketplace?
          </h3>
          <h4 className='text-secondary text-center pb-4 mb-0'>Cuéntanos qué dudas tienes y resolvámoslas juntos.</h4>
        </div>

        <Contact />

        <Footer />
        <ScrollToTop />
      </LayoutEs>
    );
  }
}

export default MarketplaceEcommerce;
