import React from 'react';
import { Link } from 'gatsby';

export default function Intro() {
  return (
    <section className="intro-section" id="start">
      <div className="container-fluid bg-grey">
        <div className="row">
          <div className='col-12 text-center'>
            <h2 className="font-size__xl my-5 text-secondary">
              Tecnología al servicio de todos
            </h2>
          </div>
          <div className="col-12 col-lg-6 block-padding__left">
            <p className='font-size__m'>
              El mayor reto de un Marketplace es contar con una infraestructura que cumpla las expectativas de todas las 
              partes implicadas en el proceso. Es decir: usuarios, vendedores y operadores.
            </p>
            <p className='font-size__m'>
              La clave está en encontrar una plataforma que proporcione un front-office atractivo e intuitivo, un 
              middle-office de fácil gestión y un back office capaz de alojar la información y los programas que tu negocio 
              necesita sin perjudicar al diseño ni a la velocidad de carga de tu eCommerce. 
            </p>
            <p className='font-size__m'>
              Puede que parezca complicado, y lo cierto es que tampoco es sencillo, pero con nuestras soluciones para Marketplace 
              y nuestra larga experiencia en el sector, las posibilidades son infinitas. 
            </p>
            <p className='font-size__m'>
              Haz <Link to="/servicios/"><strong className="text-secondary">clic aquí</strong></Link> y descubre todo lo que podemos hacer por ti.
            </p>
          </div>
          <div className="col-12 col-lg-6 bg-marketplace block-padding__right">
           
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className='col-12 text-center'>
            <h3 className="font-size__xl text-center my-5">
              ¿Qué consigues implementando un marketplace?
            </h3>
          </div>
          <div className="col-12 col-sm-6 content-section bg-primary order-0">
            <div className="row">
              <div className="col-9 mx-auto">
                <p className='font-size__m'>
                  <ul className='mb-5 text-left list-unstyled text-secondary'>
                    <li><i class="fa-solid fa-check text-secondary"></i> Aumenta la facturación un <strong>20%</strong> el primer año</li>
                    <li><i class="fa-solid fa-check text-secondary"></i> Gestión de productos y envíos online</li>
                    <li><i class="fa-solid fa-check text-secondary"></i> Autorización de proveedores</li>
                    <li><i class="fa-solid fa-check text-secondary"></i> Flexibilidad de tarifas</li>
                    <li><i class="fa-solid fa-check text-secondary"></i> Flexibilidad de comisiones</li>
                    <li><i class="fa-solid fa-check text-secondary"></i> Informes y analíticas de rendimiento</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 epo-bg order-1 pb-0 pb-lg-5">
            <div className="row text-center">
              <div className="col-9 mx-auto">
                <p className="font-size__m">
                  <ul className='mb-5 text-left list-unstyled'>
                    <li><i class="fa-solid fa-check text-primary"></i> Integración sencilla con múltiples plataformas</li>
                    <li><i class="fa-solid fa-check text-primary"></i> Frontend personalizado o externo</li>
                    <li><i class="fa-solid fa-check text-primary"></i> Herramientas de marketing y promoción</li>
                    <li><i class="fa-solid fa-check text-primary"></i> Aumento en la recurrencia de clientes</li>
                    <li><i class="fa-solid fa-check text-primary"></i> Agilidad en la gestión de vendors</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
